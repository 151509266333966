var $ = require("jquery");
import Cookies from "js-cookie";

export const ageVerification = () => {
  const modal = $("#age-modal");
  const modalBtn = $(".js-age-modal-btn");

  if (Cookies.get("ageVerification") !== "true") {
    console.log("cookieがない場合");
    modal.show();
    $("body").addClass("is-age-modal-active");
  }

  modalBtn.on("click", function () {
    modal.fadeOut();
    $("body").removeClass("is-age-modal-active");
    Cookies.set("ageVerification", "true", { expires: 7 });
    // mvAnimation発火
    $("body").addClass("is-front-mv-ready");
  });
};
