var $ = require("jquery");

export const hamburgerMenu = () => {
  const body = $("body");
  const hamburgerBtn = $(".js-hamburger-btn");

  hamburgerBtn.on("click", function () {
    body.toggleClass("is-hamburger-menu-open");
  });
};
