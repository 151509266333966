var $ = require("jquery");
import Cookies from "js-cookie";

export const mvAnimation = () => {
  $(window).on("load", function () {
    setTimeout(() => {
      if (Cookies.get("ageVerification")) {
        $("body").addClass("is-front-mv-ready");
      } else {
        return;
      }
    }, 500);
  });

  setTimeout(() => {
    // バックアップ(2秒経ってもアニメーションが発生しない&cookieに保存されている場合)
    if (!$("body").hasClass("is-front-mv-ready") && Cookies.get("ageVerification")) {
      $("body").addClass("is-front-mv-ready");
    }
  }, 2000);

  // 画像を遅延表示
  const images = $(".js-mv-img");

  images.each(function () {
    const delay = $(this).data("delay");
    $(this).css({ "animation-delay": `${delay}s` });
  });
};
