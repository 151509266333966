var $ = require("jquery");

export const mwwp = () => {
  var headerHeight = $(".header").height();

  // js-page-application-confirmがあるページ

  if ($(".js-page-application-confirm").length > 0) {
    // valueが消えてしまうため直接挿入
    const select = $(".js-select-your-date");
    const value = select.find("input").val();
    select.append(value);

    // 「段ボールを希望する」がいいえの場合、3つを非表示
    const cardboardOptions = $(".js-cardboard-options");
    const cardboardSelect = $(".js-cardboard");
    const cardboardSelectValue = cardboardSelect.find("input[name=cardboard]").val();
    if (cardboardSelectValue === "いいえ") {
      cardboardOptions.remove();
    }
  }

  // !!!!!!!!テスト
  // $("body").css({ color: "yellow" });

  // mw_wp_form_inputがあるページ
  if ($(".mw_wp_form_input").length > 0) {
    // 郵便番号の入力inputのtypeをtelにする
    if ($("input[name=post]").length > 0) {
      $("input[name=post]").attr("type", "tel");
    }

    // cardboard-dateのoptions1つめにselectedを付与
    const cardboardDate = $("select[name=cardboard-date]");
    cardboardDate.find("option").eq(0).attr("selected", "");
    console.log(cardboardDate);

    // エラー発生時
    if ($(".error").length > 0) {
      $(".form_group2-wrap").fadeIn();
      $(".form_group3-wrap").fadeIn();
      $(".bottom_text").fadeIn(0);
      $(".contact_btn").fadeIn(0);
      $(".contact_area").addClass("show_error");
      $(".error").each(function () {
        var parent_class = $(this).parent().attr("id");
        if (parent_class && parent_class.indexOf("select_box") > -1) {
          $(this).insertAfter("#" + parent_class);
        }
      });
    }

    // フラグ
    var flag = true;

    $(".form_group3").each(function () {
      var val = $(this).val();
      if (!val || val == "選択してください") {
        flag = false;
      }
    });

    if (flag) {
      $(".form_group2-wrap").fadeIn();
      $(".form_group3-wrap").fadeIn();
      $(".form_group4-wrap").fadeIn();
      $(".bottom_text").fadeIn(0);
      $(".contact_btn").fadeIn(0);
    }

    /* =================================================== */
    // ロード時
    // 「段ボールを希望する」の値を取得
    var cardboardSelect = $("select[name=cardboard]").val();
    // 3つの選択肢を取得
    var cardboardOptions = $(".js-cardboard-options");

    // いいえの場合、下3つの選択肢を消す
    if (cardboardSelect === "いいえ") {
      cardboardOptions.fadeOut();
    }
    // はいの場合は表示
    else {
      cardboardOptions.fadeIn();
      $(".form_group1").each(function () {
        var val = $(this).val();
        if (!val || val == "選択してください") {
          flag = false;
        } else {
          flag = true;
        }
      });
    }

    // 引取希望日の値を動的に追加
    var yourDateSelect = $("select[name=your-date]");
    // 一旦optionを削除(flagが変わるたびに追加されるため)
    var yourDateOptionTags = $("select[name=your-date] option:not(:first)");
    yourDateOptionTags.remove();

    // 開始日を取得
    var yourDateStartDate;

    // 「はい」の場合は開始日をcardboard-dateで指定した値を開始日にする
    if ($("select[name=cardboard]").val() === "はい") {
      yourDateStartDate = $("select[name=cardboard-date]").val();
      const yourDateOptions = getDateOptions(yourDateStartDate, true);

      var keys = Object.keys(yourDateOptions);
      keys.forEach(function (key, i) {
        /// option要素を動的に生成＆追加
        var content = this[key];
        var option = $("<option>").text(content["text"]).val(content["value"]);
        yourDateSelect.append(option);
      }, yourDateOptions);
    }
    // 「いいえ」の場合は今日の日付を開始日にする
    else {
      const dateData = new Date();
      const year = dateData.getFullYear();
      const month = dateData.getMonth() + 1;
      const date = dateData.getDate();
      const day = dateData.getDay();
      const weekday = ["（日）", "（月）", "（火）", "（水）", "（木）", "（金）", "（土）"];

      const optionValue = `${year}/${month}/${date}${weekday[day]}`;
      yourDateStartDate = optionValue;
      const yourDateOptions = getDateOptions(yourDateStartDate, false);

      var keys = Object.keys(yourDateOptions);
      keys.forEach(function (key, i) {
        /// option要素を動的に生成＆追加
        var content = this[key];
        var option = $("<option>").text(content["text"]).val(content["value"]);
        yourDateSelect.append(option);
      }, yourDateOptions);
    }

    /* =================================================== */
  }

  $(".form_group1").on("change keyup", function () {
    var flag = true;

    $(".form_group1").each(function () {
      var val = $(this).val();
      if (!val || val == "選択してください") {
        flag = false;
      } else {
        flag = true;
      }
    });

    // 「段ボールを希望する」の値を取得
    var cardboardSelect = $("select[name=cardboard]").val();
    // 3つの選択肢を取得
    var cardboardOptions = $(".js-cardboard-options");

    // いいえの場合、下3つの選択肢を消す
    if (cardboardSelect === "いいえ") {
      flag = true;

      cardboardOptions.fadeOut();
    } else {
      cardboardOptions.fadeIn();
      flag = false;

      $(".form_group1").each(function () {
        var val = $(this).val();
        if (!val || val == "選択してください") {
          flag = false;
        } else {
          flag = true;
        }
      });
    }

    if (flag) {
      // グループ2を表示
      $(".form_group2-wrap").fadeIn(500);

      // 引取希望日の値を動的に追加
      var yourDateSelect = $("select[name=your-date]");
      // 一旦optionを削除(flagが変わるたびに追加されるため)
      var yourDateOptionTags = $("select[name=your-date] option:not(:first)");
      yourDateOptionTags.remove();

      // 開始日を取得
      var yourDateStartDate;

      // 「はい」の場合は開始日をcardboard-dateで指定した値を開始日にする
      if ($("select[name=cardboard]").val() === "はい") {
        yourDateStartDate = $("select[name=cardboard-date]").val();

        const yourDateOptions = getDateOptions(yourDateStartDate, true);

        var keys = Object.keys(yourDateOptions);
        keys.forEach(function (key, i) {
          /// option要素を動的に生成＆追加
          var content = this[key];
          var option = $("<option>").text(content["text"]).val(content["value"]);
          yourDateSelect.append(option);
        }, yourDateOptions);
      }
      // 「いいえ」の場合は今日の日付を開始日にする
      else {
        const dateData = new Date();
        const year = dateData.getFullYear();
        const month = dateData.getMonth() + 1;
        const date = dateData.getDate();
        const day = dateData.getDay();
        const weekday = ["（日）", "（月）", "（火）", "（水）", "（木）", "（金）", "（土）"];

        const optionValue = `${year}/${month}/${date}${weekday[day]}`;
        yourDateStartDate = optionValue;
        const yourDateOptions = getDateOptions(yourDateStartDate, false);

        var keys = Object.keys(yourDateOptions);
        keys.forEach(function (key, i) {
          /// option要素を動的に生成＆追加
          var content = this[key];
          var option = $("<option>").text(content["text"]).val(content["value"]);
          yourDateSelect.append(option);
        }, yourDateOptions);
      }

      setTimeout(() => {
        scrollToTarget(".form_group2-wrap");
      }, 500);
    }
  });

  $(".form_group2").on("change", function () {
    var flag = true;
    $(".form_group2").each(function () {
      var val = $(this).val();
      if (!val || val == "選択してください") {
        flag = false;
      }
    });
    if (flag) {
      $(".form_group3-wrap").fadeIn(500);
      setTimeout(() => {
        scrollToTarget(".form_group3-wrap");
      }, 300);
    }
  });
  $(".form_group3").on("change", function () {
    var flag = true;
    $(".form_group3").each(function () {
      var val = $(this).val();
      if (!val || val == "選択してください") {
        flag = false;
      }
    });
    if (flag) {
      $(".form_group4-wrap").fadeIn(500);
      scrollToTarget(".form_group4-wrap");
    }
  });

  $(".form_group4").on("change", function () {
    var flag = true;
    $(".form_group4").each(function () {
      var val = $(this).val();
      if (!val || val == "選択してください") {
        flag = false;
      }
    });
    if (flag) {
      $(".bottom_text").fadeIn(0);
      $(".contact_btn").fadeIn(0);
      var scrTop = $(".bottom_text").offset().top - 80;
      $("html,body").animate({ scrollTop: scrTop }, "400", "swing");
    }
  });

  var datefield = document.createElement("input");

  datefield.setAttribute("type", "date");

  // if (datefield.type != "date") {
  //   //if browser doesn't support input type="date", load files for jQuery UI Date Picker
  //   document.write('<link href="http://ajax.googleapis.com/ajax/libs/jqueryui/1.12.1/themes/base/jquery-ui.css" rel="stylesheet" type="text/css" />\n');
  //   document.write('<script src="http://ajax.googleapis.com/ajax/libs/jqueryui/1.12.1/jquery-ui.min.js"></script>\n');
  // }
  // if (datefield.type != "date") {
  //   //if browser doesn't support input type="date", initialize date picker widget:
  //   $(document).ready(function () {
  //     $("#date").datepicker();
  //   });
  // }

  // 対象要素の地点までスクロール
  function scrollToTarget(target) {
    var scrTop = $(target).offset().top - headerHeight;
    $("html,body").animate({ scrollTop: scrTop }, "400", "swing");
  }

  // optionの中身の日付を取得
  function getDateOptions(startDate, needCardboard) {
    console.log(startDate);
    const startDateVal = startDate.slice(0, -3);

    const options = [];

    if (needCardboard) {
      // 段ボールが必要な場合、指定した日付の翌日から14日間
      for (let i = 1; i <= 14; i++) {
        const dateData = new Date(startDateVal);
        const optionValue = formatDate(dateData, i);

        options.push({
          text: optionValue,
          value: optionValue,
        });
      }
      return options;
    } else {
      // 段ボールが不要な場合、今日の日付の2日後から14日間
      for (let i = 1; i <= 15; i++) {
        if (i <= 1) {
          continue;
        }

        const dateData = new Date(startDateVal);
        const optionValue = formatDate(dateData, i);

        options.push({
          text: optionValue,
          value: optionValue,
        });
      }
      return options;
    }
  }

  // 日付のテキストをXXXX/XX/XX/(X)のフォーマットにする
  function formatDate(dateObj, iterator) {
    dateObj.setDate(dateObj.getDate() + iterator);
    const year = dateObj.getFullYear();
    const month = dateObj.getMonth() + 1;
    const date = dateObj.getDate();
    const day = dateObj.getDay();
    const weekday = ["（日）", "（月）", "（火）", "（水）", "（木）", "（金）", "（土）"];

    const formattedDateText = `${year}/${month}/${date}${weekday[day]}`;

    return formattedDateText;
  }
};
