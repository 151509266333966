var $ = require("jquery");

export const floatBtn = () => {
  const btn = $(".float-btn");

  let scroll = 0;
  $(window).on("scroll", function () {
    if (btn.css("display") == "flex") {
      // 上スクロール
      if ($(this).scrollTop() < scroll) {
        if ($("body").hasClass("is-float-btn-visible")) {
          $("body").removeClass("is-float-btn-visible");
        }
      }
      // 下スクロール
      else {
        if (!$("body").hasClass("is-float-btn-visible")) {
          $("body").addClass("is-float-btn-visible");
        }
      }
      scroll = $(this).scrollTop();
    }
  });
};
