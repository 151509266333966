"use strict";

var $ = require("jquery");
import { ageVerification } from "./modules/ageVerification";
import { accordion, faqCategoryAccordion } from "./modules/accordion";
import { mvAnimation } from "./modules/mvAnimation";
import { mwwp } from "./modules/mwwp";
import { hamburgerMenu } from "./modules/hamburgerMenu";
import { scrollOnLoad } from "./modules/scrollOnLoad";
import { floatBtn } from "./modules/floatBtn";

$(window).on("load", function (e) {
  e.preventDefault();
  scrollOnLoad();
});

$(function () {
  ageVerification();
  accordion();
  faqCategoryAccordion();
  mvAnimation();
  mwwp();
  hamburgerMenu();
  floatBtn();
});

// Front page only
if ($(".js-front-page").length > 0) {
}
