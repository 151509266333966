var $ = require("jquery");

export const scrollOnLoad = () => {
  let pathName = location.pathname;
  const pattern = "/faq/";
  $("body,html").stop().scrollTop(0);

  if (pathName.indexOf(pattern) > -1 && location.hash !== "") {
    console.log("code is working");
    var headerHeight = $(".header").height();
    var hash = location.hash;
    // $("body,html").stop().scrollTop(0);

    if (hash) {
      const target = $(hash);
      const targetParent = target.parent();

      setTimeout(() => {
        targetParent.css("display", "block");
        targetParent.prev().addClass("is-open");
        const targetDistance = target.offset().top - headerHeight;

        setTimeout(() => {
          $("html,body").animate({ scrollTop: targetDistance }, 500);
        }, 200);
      }, 500);
    }
  } else {
    var headerHeight = $(".header").height();
    var hash = $(location).attr("hash");
    $("html,body").scrollTop(0);
    if (hash) {
      var target = $(hash).offset().top - headerHeight;
      setTimeout(() => {
        $("html,body").animate({ scrollTop: target }, 500);
      }, 300);
    }
  }
};
