var $ = require("jquery");

export const accordion = () => {
  $(".js-accordion-content").hide();
  $(".js-accordion-content").eq(0).show();
  $(".js-accordion-content").eq(0).parent().addClass("is-open");

  $(".js-accordion-trigger").on("click", function () {
    $(this).toggleClass("is-open");
    $(this).find(".js-accordion-content").slideToggle();
  });
};

export const faqCategoryAccordion = () => {
  $(".js-faq-cat-accordion-content").hide();

  $(".js-faq-cat-accordion-trigger").on("click", function () {
    $(this).toggleClass("is-open");
    $(this).next(".js-faq-cat-accordion-content").slideToggle(600);
  });
};
